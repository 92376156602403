import { Spinner } from '@amzn/awsui-components-react/polaris';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { LicenseManager } from 'ag-grid-enterprise';
import { FC, PropsWithChildren, useEffect } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';
import GlobalErrorModal from 'src/common/GlobalErrorModal';
import { NavBar } from 'src/common/NavBar';
import { useUserAlias } from 'src/hooks/useUserAlias';

const AG_GRID_LICENSE =
  'CompanyName=Amazon.com, Inc.,LicensedGroup=WWCFT,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=2,AssetReference=AG-025476,ExpiryDate=1_March_2023_[v2]_MTY3NzYyODgwMDAwMA==d148a2e5e458641d5c00ff612cdc8839';

LicenseManager.setLicenseKey(AG_GRID_LICENSE);

/**
 * Make sure the application is loaded after feature flags are fetched
 */
const FeatureFlagsLoader: FC<PropsWithChildren> = ({ children }) => {
  const { isFetched: isFeatureFlagFetchSuccess } = useFeatureFlags();
  return isFeatureFlagFetchSuccess ? children : null;
};

/**
 * React component to authenticate users
 */
const RootPage: FC = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const { alias } = useUserAlias();

  // Dispatch user info
  // Ref: https://code.amazon.com/packages/CathodeVersionsJavaScript/trees/mainline
  useEffect(() => {
    if (route !== 'authenticated') {
      return;
    }
    const authenticatedUserEvent = new CustomEvent('cathode-customerId', {
      detail: {
        customerId: alias,
      },
    });
    document.dispatchEvent(authenticatedUserEvent);
  }, [route, alias]);

  if (route !== 'authenticated') return <Spinner />;

  return (
    <FeatureFlagsLoader>
      <ScrollRestoration
        getKey={(location, _matches) => {
          const paths = ['/plans'];
          return paths.includes(location.pathname) ? location.pathname : location.key;
        }}
      />
      <NavBar />
      <div className="main-content">
        <Outlet />
      </div>
      <GlobalErrorModal />
    </FeatureFlagsLoader>
  );
};

export default RootPage;
