import { TFunction } from 'i18next';
import {
  DatasetInfo,
  SubTabConfig,
  SubTabDetail,
} from 'src/pages/plan-manager-page/plan-input-tab/planInputConfig';
import { GetDatasetOptions, GetSubTabs } from 'src/pages/commons/plan-views/DatasetTabsViewer';
import { getSchemaForDataset, IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';
import {
  DATASET_TYPE_LABEL_MAP,
  SupportedDatasetType,
  PlanTypeId,
} from 'src/utils/planning/planetModel';

export enum PlanOutputSubTab {
  PLAN_OUTPUT = 'PLAN_OUTPUT',
  CPU_MM_FORECAST = 'CPU_MM_FORECAST',
  LUNA_OUTPUT = 'LUNA_OUTPUT',
}

export const getOutputSubTabs: GetSubTabs<PlanOutputSubTab> = (batchMetadata, t) => {
  if (!batchMetadata?.costType) return null;

  const { costType } = batchMetadata;

  const planTypeSubTabMap: Record<PlanTypeId, SubTabConfig<PlanOutputSubTab>[]> = {
    [PlanTypeId.CAPEX]: [
      {
        id: PlanOutputSubTab.PLAN_OUTPUT,
        datasets: [SupportedDatasetType.CAPEX_PLANNING_FINAL_OUTPUT],
      },
    ],
    [PlanTypeId.DEPRECIATION]: [
      {
        id: PlanOutputSubTab.PLAN_OUTPUT,
        datasets: [SupportedDatasetType.DEPRECIATION_OUTPUT_PLAN],
      },
    ],
    [PlanTypeId.REAL_ESTATE]: [
      {
        id: PlanOutputSubTab.PLAN_OUTPUT,
        datasets: [SupportedDatasetType.REAL_ESTATE_OUTPUT_PLAN],
      },
    ],
    [PlanTypeId.JANITORIAL]: [
      {
        id: PlanOutputSubTab.PLAN_OUTPUT,
        datasets: [SupportedDatasetType.JANITORIAL_OUTPUT_PLAN],
      },
    ],
    [PlanTypeId.SECURITY]: [
      {
        id: PlanOutputSubTab.PLAN_OUTPUT,
        datasets: [SupportedDatasetType.SECURITY_OUTPUT_PLANET_SECURITY_PLAN],
      },
    ],
    [PlanTypeId.THREE_P_TRANSPORTATION]: [
      {
        id: PlanOutputSubTab.PLAN_OUTPUT,
        datasets: [SupportedDatasetType.THREE_P_TRANSPORTATION_OUTPUT_PLANET_3P_PLAN_WW],
      },
    ],
    [PlanTypeId.OTR_WAREHOUSE_TRANSFER]: [
      {
        id: PlanOutputSubTab.PLAN_OUTPUT,
        datasets: [SupportedDatasetType.PLANET_OTR_WHT_OUTPUT_MAIN_COST],
      },
    ],
    [PlanTypeId.OTR_DOMESTIC_INBOUND]: [
      {
        id: PlanOutputSubTab.PLAN_OUTPUT,
        datasets: [SupportedDatasetType.PLANET_OTR_DIB_OUTPUT],
      },
    ],
    [PlanTypeId.OTR_FREIGHT_CAPITALIZATION]: [
      {
        id: PlanOutputSubTab.PLAN_OUTPUT,
        datasets: [SupportedDatasetType.PLANET_OTR_FC_OUTPUT_WW],
      },
    ],
    [PlanTypeId.OTR_SUPPLY_TYPE]: [],
    [PlanTypeId.TOPS_DOWN_FORECAST]: [
      {
        id: PlanOutputSubTab.CPU_MM_FORECAST,
        datasets: [
          SupportedDatasetType.TOPS_DOWN_FORECAST_CPU_FORECAST,
          SupportedDatasetType.TOPS_DOWN_FORECAST_MM_FORECAST,
        ],
      },
      {
        id: PlanOutputSubTab.LUNA_OUTPUT,
        datasets: [SupportedDatasetType.TOPS_DOWN_FORECAST_LUNA_OUTPUT],
      },
    ],
    [PlanTypeId.UTR_PRODUCTIVITY]: [
      {
        id: PlanOutputSubTab.PLAN_OUTPUT,
        datasets: [SupportedDatasetType.UTR_PRODUCTIVITY_OUTPUT_PLAN],
      },
    ],
    [PlanTypeId.UTR_COST_HC]: [
      {
        id: PlanOutputSubTab.PLAN_OUTPUT,
        datasets: [SupportedDatasetType.PLANET_UTR_COST_HC_OUTPUT_PLAN],
      },
    ],
    [PlanTypeId.FIXED_COST_CONSOLIDATION]: [
      {
        id: PlanOutputSubTab.PLAN_OUTPUT,
        datasets: [SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_OUTPUT],
      },
    ],
    [PlanTypeId.CF_CONSOLIDATION]: [
      {
        id: PlanOutputSubTab.PLAN_OUTPUT,
        datasets: [SupportedDatasetType.CF_CONSOLIDATION_COMBINED_OUTPUT],
      },
    ],
  };

  const subTabDetailMap: Record<PlanOutputSubTab, SubTabDetail> = {
    [PlanOutputSubTab.PLAN_OUTPUT]: {
      label: t('plan_output_label'),
      descriptionDiv: t('plan_output_container_description'),
    },
    [PlanOutputSubTab.CPU_MM_FORECAST]: {
      label: t('cpu_mm_forecast_table_label'),
      descriptionDiv: t('plan_output_container_description'),
    },
    [PlanOutputSubTab.LUNA_OUTPUT]: {
      label: t('luna_output_label'),
      descriptionDiv: t('plan_output_container_description'),
    },
  };

  return planTypeSubTabMap[costType as PlanTypeId].map((o) => ({
    ...o,
    ...subTabDetailMap[o.id],
    datasets: o.datasets.map((id) => ({ id, label: DATASET_TYPE_LABEL_MAP[id] })),
  }));
};

export const getOutputDatasetOptions: GetDatasetOptions = (batchMetadata, datasets) => {
  if (!batchMetadata?.costType) return [];
  if (!datasets) return [];

  return datasets.map((dataset) => ({
    label: dataset.label,
    value: dataset.id,
    disabled: !getSchemaForDataset(dataset.id, batchMetadata),
  }));
};

export const getOutputDatasets = (
  batchMetadata: IBatchMetadataParsed | undefined,
  t: TFunction<'translation', undefined>,
): DatasetInfo[] | null => {
  const outputSubTabs = getOutputSubTabs(batchMetadata, t);
  if (!outputSubTabs) return null;
  return outputSubTabs.reduce<DatasetInfo[]>((acc, curr) => [...acc, ...curr.datasets], []);
};

export const AG_OUTPUT_PAGINATION_PAGE_SIZE = 50;
