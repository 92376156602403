import {
  ColumnPivotChangedEvent,
  ColumnPivotModeChangedEvent,
  ColumnRowGroupChangedEvent,
  ColumnValueChangedEvent,
} from 'ag-grid-community';
import { useParams } from 'react-router-dom';
import { LocalStorageKeys, useLocalStorage } from 'src/hooks/useLocalStorage';
import { useQueryParams } from 'src/hooks/useQueryParams';
import { SupportedDatasetType } from 'src/utils/planning/planetModel';

class AgGridPivotModeConfig {
  rowGroupColumns: string[] = [];
  pivotColumns: string[] = [];
  valueColumns: { colId: string; aggFunc: string }[] = [];
}

/** default configuration for each dataset (if applicable) */
const DATASET_DEFAULT_CONFIG_MAP: Partial<Record<SupportedDatasetType, AgGridPivotModeConfig>> = {
  // TODO (davin): add tops down configuration after confirming with pm
};

const DEFAULT_AGG_FUNC = 'sum';

export const useAgGridPivotModeConfig = () => {
  const { batchId } = useParams();

  const { queryParams } = useQueryParams<{ dataset: SupportedDatasetType }>();

  const [pivotModeConfig, setPivotModeConfig] = useLocalStorage<AgGridPivotModeConfig>({
    key: [LocalStorageKeys.AG_GRID_PIVOT_CONFIG, batchId!, queryParams.dataset],
    defaultValue: DATASET_DEFAULT_CONFIG_MAP[queryParams.dataset] ?? new AgGridPivotModeConfig(),
  });

  /** for pivot mode, save rowGroupColumns configuration to localStorage */
  const onColumnRowGroupChanged = (e: ColumnRowGroupChangedEvent) => {
    if (e.columnApi.isPivotMode()) {
      setPivotModeConfig({
        ...pivotModeConfig,
        rowGroupColumns: e.columnApi.getRowGroupColumns().map((o) => o.getColId()),
      });
    }
  };

  /** for pivot mode, save pivotColumns configuration to localStorage */
  const onColumnPivotChanged = (e: ColumnPivotChangedEvent) => {
    if (e.columnApi.isPivotMode()) {
      setPivotModeConfig({
        ...pivotModeConfig,
        pivotColumns: e.columnApi.getPivotColumns().map((o) => o.getColId()),
      });
    }
  };

  /** for pivot mode, save valueColumns and aggregate functions configuration to localStorage */
  const onColumnValueChanged = (e: ColumnValueChangedEvent) => {
    if (e.columnApi.isPivotMode()) {
      setPivotModeConfig({
        ...pivotModeConfig,
        valueColumns: e.columnApi.getValueColumns().map((o) => ({
          colId: o.getColId(),
          aggFunc: o.getAggFunc() as string,
        })),
      });
    }
  };

  /** clean up all configured columns & restore from localStorage for pivot mode */
  const onColumnPivotModeChanged = (e: ColumnPivotModeChangedEvent) => {
    /** clean up all aggregate functions */
    for (const col of e.columnApi.getValueColumns()) {
      e.columnApi.setColumnAggFunc(col.getColId(), DEFAULT_AGG_FUNC);
    }

    /** clean up all configured columns */
    e.columnApi.setRowGroupColumns([]);
    e.columnApi.setPivotColumns([]);
    e.columnApi.setValueColumns([]);

    /** for pivot mode, restore configured columns from localStorage */
    if (e.columnApi.isPivotMode()) {
      /** restore rowGroupColumns and pivotColumns */
      e.columnApi.setRowGroupColumns(pivotModeConfig.rowGroupColumns);
      e.columnApi.setPivotColumns(pivotModeConfig.pivotColumns);

      /** restore valueColumns and aggregate functions */
      for (const { colId, aggFunc } of pivotModeConfig.valueColumns) {
        e.columnApi.addValueColumn(colId);
        e.columnApi.setColumnAggFunc(colId, aggFunc);
      }
    }
  };

  return {
    onColumnRowGroupChanged,
    onColumnPivotChanged,
    onColumnValueChanged,
    onColumnPivotModeChanged,
  };
};
